import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '../login/login.component';
import { HomeComponent } from '../home/home.component';
import { CadastroUsuarioComponent } from '../admin/cadastro-usuario/cadastro-usuario.component';
import { ListaUsuarioComponent } from '../admin/lista-usuario/lista-usuario.component';

import { RegraUploadComponent } from '../upload/regra-upload/regra-upload.component';
import { TesteUploadComponent } from '../upload/teste-upload/teste-upload.component';
import { RegraPendenteComponent } from '../regra/regra-pendente/regra-pendente.component';
import { RegraPendenteAprovacaoComponent } from '../regra/regra-pendente-aprovacao/regra-pendente-aprovacao.component';

import { ConsultaRegraComponent } from '../regra/consulta-regra/consulta-regra.component';
import { EditarRegraComponent } from '../regra/editar-regra/editar-regra.component';
import { VisualizaRegraComponent } from '../regra/visualiza-regra/visualiza-regra/visualiza-regra.component';

import { ConsultaPalavraChaveComponent } from '../palavra-chave/consulta-palavra-chave/consulta-palavra-chave.component';

import { ConsultaPecaComponent } from '../peca/consulta-peca/consulta-peca.component';

import { ConsultaPerfilComponent } from '../perfil/consulta-perfil/consulta-perfil.component';
import { VisualizaFuncPerfilComponent } from '../perfil/visualiza-func-perfil/visualiza-func-perfil.component';
import { EditaFuncPerfilComponent } from '../perfil/edita-func-perfil/edita-func-perfil.component';
import { CriarPerfilComponent } from '../perfil/criar-perfil/criar-perfil.component';

import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
  // tslint:disable-next-line:comment-format

  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'admin/cadastro', component: CadastroUsuarioComponent, canActivate: [AuthGuard] },
  { path: 'admin/lista', component: ListaUsuarioComponent, canActivate: [AuthGuard] },
  { path: 'perfil/lista', component: ConsultaPerfilComponent, canActivate: [AuthGuard] },
  { path: 'perfil/criar', component: CriarPerfilComponent, canActivate: [AuthGuard] },
  { path: 'perfil/funcionalidade/editar', component: EditaFuncPerfilComponent, canActivate: [AuthGuard] },
  { path: 'perfil/funcionalidade/visualizar', component: VisualizaFuncPerfilComponent, canActivate: [AuthGuard] },

  { path: 'arquivo/regra', component: RegraUploadComponent, canActivate: [AuthGuard] },
  { path: 'arquivo/teste', component: TesteUploadComponent, canActivate: [AuthGuard] },

  { path: 'regra/pendentes', component: RegraPendenteComponent, canActivate: [AuthGuard] },
  { path: 'regra/pendente/aprovacao', component: RegraPendenteAprovacaoComponent, canActivate: [AuthGuard] },

  { path: 'regra/consulta', component: ConsultaRegraComponent, canActivate: [AuthGuard] },
  { path: 'regra/visualiza', component: VisualizaRegraComponent, canActivate: [AuthGuard] },
  { path: 'regra/editar', component: EditarRegraComponent, canActivate: [AuthGuard] },

  { path: 'palavra-chave/lista', component: ConsultaPalavraChaveComponent, canActivate: [AuthGuard] },

  { path: 'peca/lista', component: ConsultaPecaComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }) ],
  exports: [ RouterModule ]
})
export class RoutingModule { }

