import { Component, OnInit, OnDestroy } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MessageService } from "../../messages/message.service";
import { Pessoa } from "../../model/Pessoa";
import { Perfil } from "../../model/Perfil";
import { CadastroUsuarioService } from "./cadastro-usuario.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-cadastro",
  templateUrl: "./cadastro-usuario.component.html",
  styleUrls: ["./cadastro-usuario.component.css"],
})
export class CadastroUsuarioComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;
  pessoa: Pessoa = new Pessoa();
  lista: Perfil[];
  sexo = ["Masculino", "Feminino"];
  status = ["Ativo", "Inativo"];

  constructor(
    private cadastroUsuarioService: CadastroUsuarioService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.cadastroUsuarioService.getListaPerfil().subscribe((data) => {
      this.lista = data;
    });
  }

  onSubmit() {
    try {
      this.blockUI.start("Processando..."); // Start blocking
      if (
        this.pessoa.nome != null &&
        this.pessoa.login != null &&
        this.pessoa.email != null &&
        this.pessoa.senha != null &&
        this.pessoa.status != null &&
        this.pessoa.perfil != null
      ) {
        this.ngOnDestroy();
        this.cadastroUsuarioService.getLogin(this.pessoa.login).subscribe(
          (data) => {
            if (data != null) {
              this.messageService.sendMessage("Login já registrado");
              this.blockUI.stop();
            } else {
              this.cadastroUsuarioService
                .setPessoa(this.pessoa)
                .subscribe((x) => {
                  console.log(this.pessoa);
                  this.messageService.sendMessage(
                    "Usuário cadastrado com sucesso"
                  );
                  this.blockUI.stop();
                });
            }

          },
          (error) => {
            this.cadastroUsuarioService
              .setPessoa(this.pessoa)
              .subscribe((x) => {
                console.log(this.pessoa);
                this.messageService.sendMessage(
                  "Usuário cadastrado com sucesso"
                );
              });
            this.blockUI.stop();
          }
        );
      } else {
        this.blockUI.stop();
        this.messageService.sendMessage(
          "Todos os campos devem ser preenchidos para o cadastro do Usuario"
        );
      }
    } catch (e) {
      this.blockUI.stop();
    }
  }

  ngOnDestroy() {
    this.messageService.clearMessage();
  }

  backtoList() {
    this.router.navigateByUrl("admin/lista");
  }
}
