import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pessoa } from '../model/Pessoa';
import { LoginPerfil } from '../model/LoginPerfil';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url: string = environment.apiUrl;

  constructor(private http: HttpClient) {
    //console.log(environment.apiUrl);
  }


  public login(login: Pessoa): Observable<LoginPerfil> {
    return this.http
      .post<LoginPerfil>(this.url + '/pessoa/login/',login);
  }

}
