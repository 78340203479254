import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { Perfil } from '../model/Perfil';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  perfilEditarVisualizar = new BehaviorSubject<string>('');
  idEditarVisualizar = new BehaviorSubject<string>('');
  @BlockUI() blockUI: NgBlockUI;

  constructor(private http: HttpClient) { }

  setPerfilEditarVisualizar(valor: string){
    this.perfilEditarVisualizar.next(valor);
  }

  getPerfilEditarVisualizar(){
    return this.perfilEditarVisualizar.getValue();
  }

  setIdPerfilEditarVisualizar(valor: string){
      this.idEditarVisualizar.next(valor);
    }

  getIdPerfilEditarVisualizar(){
    return this.idEditarVisualizar.getValue();
  }

  getListaPerfil(perfil: Perfil, paginacao: number): Observable<any[]>{
    const url = environment.apiUrl + "/perfil/pesquisarPerfil/";
    return this.http.post<any[]>(url + paginacao.toString(), perfil);
  }

  createPerfil(perfil: Perfil): Observable<any>{
    const url = environment.apiUrl + "/perfil/salvaPerfil/";
    return this.http.post<any>(url, perfil)
  }

  getPerfil(id: string): Observable<any>{
    const url = environment.apiUrl + "/perfil/buscaPerfilPorId/";
    return this.http.get<any>(url + id);
  }

  removePerfil(id: string): Observable<any>{
    const url = environment.apiUrl + "/perfil/removePerfil/";
    return this.http.delete<any>(url + id);
  }

  createFuncionalidadePerfil(perfil: Perfil, codigo: string): Observable<any>{
    const url = environment.apiUrl + "/perfil/salvaFuncionalidadePerfil/";
    return this.http.post<any>(url + codigo, perfil.perfil)
  }

  removeFuncionalidadePerfil(perfil: string): Observable<any> {
    const url = environment.apiUrl + "/funcionalidadePerfil/deletarPerfil/";
    return this.http.delete<any>(url + perfil);
  }

  getListaPerfilFuncionalidade(perfil: string): Observable<any[]>{
    const url = environment.apiUrl + "/funcionalidadePerfil/getByPerfil/";
    return this.http.get<any[]>(url + perfil);
  }

  saveFuncionalidadePerfil(perfil: Perfil): Observable<any>{
    const url = environment.apiUrl + "/funcionalidadePerfil/salvarFuncionalidadePerfil";
    return this.http.post<any>(url, perfil);
  }

  updateFuncionalidadePerfil(nomePerfil: string, perfil: Perfil): Observable<any>{
    const url = environment.apiUrl + "/funcionalidadePerfil/atualizaPerfil/";
    return this.http.put<any>(url + nomePerfil, perfil);
  }

  verificaFuncionalidadePerfil(nomePerfil: string, perfil: Perfil): Observable<any>{
    const url = environment.apiUrl + "/funcionalidadePerfil/verificaPerfil/";
    return this.http.post<any>(url + nomePerfil, perfil);
  }
}
