import {Component, OnDestroy, OnInit} from '@angular/core';
import { UploadFileService } from '../upload-file.service';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { MessageService } from '../../messages/message.service';
import { Observable } from 'rxjs';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
  selector: 'app-teste-upload',
  templateUrl: './teste-upload.component.html',
  styleUrls: ['./teste-upload.component.css']
})
export class TesteUploadComponent  implements OnInit, OnDestroy  {

  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  fileUploads: Array<any>;
  @BlockUI() blockUI: NgBlockUI;
  currentPage = 1;
  totalRecord = 0;

  fileIdApagar: string;
  fileNameApagar: string;
  fileDateApagar: string;

  constructor(private uploadService: UploadFileService, private messageService: MessageService) { }

  ngOnInit() {
    this.showFiles();
  }

  ngOnDestroy() {
    this.clearMessage();
  }
  sendMessage(): void {
    // send message to subscribers via observable subject
    this.messageService.sendMessage('Message from Home Component to App Component!');
  }

  clearMessage(): void {
    this.messageService.clearMessage();
  }

  selectFile(event) {
    this.currentFileUpload = null;
    this.selectedFiles = event.target.files;
    this.clearMessage();
    this.progress.percentage = 0;
  }

  upload() {
    this.blockUI.start('Processando...');
    this.progress.percentage = 0;

    this.currentFileUpload = this.selectedFiles.item(0);
    this.uploadService.pushFileToStorage(this.currentFileUpload).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log('Archivos de reglas importados correctamente !');
          this.currentPage = 1;
          this.showFiles();
          this.messageService.sendMessage('Archivos de reglas importados correctamente !');
          this.selectedFiles = undefined;
          this.blockUI.stop();
        }
      },
      error => {
        console.log('opps', error.error);
        this.currentPage = 1;
        this.showFiles();
        this.messageService.sendMessage(error.error);
        this.selectedFiles = undefined;
        this.blockUI.stop();
      }
    );
    this.selectedFiles = undefined;
  }

  setPage(i: number) {
    event.preventDefault();
    this.currentPage = i;
    this.showFiles();
  }

  showFiles() {
    try {
      this.blockUI.start('Processando...');
      this.ngOnDestroy();
      this.uploadService
        .getFilesTeste(this.currentPage)
        .subscribe(
          regras => {
            this.fileUploads = regras ['content'];
            this.totalRecord = regras ['totalElements'];
            if (this.fileUploads === null || this.fileUploads.length === 0 ) {
              this.messageService.sendMessage('No se encontraron registros');
            }
            this.blockUI.stop();
          },
          (error) => {
            this.blockUI.stop();
            console.log('Erro');
          }
        );
    } catch (e) {
      this.blockUI.stop();
    }
  }

  setFileApagar(fileId: string, fileName: string, fileDate: string)
  {
    this.fileIdApagar = fileId;
    this.fileNameApagar = fileName;
    this.fileDateApagar = fileDate;
  }

  deletar(fileId: string, fileName: string, fileDate: string)
  {
    try {
        this.uploadService.deletarUploadTeste(fileId).subscribe(
          data => {
            console.log('Deletado com sucesso');
            this.showFiles();
            this.messageService.sendMessage('Eliminado con éxito.');
          },(error) => {
            //this.blockUI.stop();
            console.log('Erro');
          }
        );
      } catch (e) {
        //this.blockUI.stop();
     }
  }

  deleteAll()
  {
    try {
        this.uploadService.deletarAllUploadTeste().subscribe(
          data => {
            console.log('Deletado com sucesso');
            this.showFiles();
            //this.messageService.sendMessage('Deletado com sucesso.');
          },(error) => {
            //this.blockUI.stop();
            console.log('Erro');
          }
        );
      } catch (e) {
        //this.blockUI.stop();
     }
  }

}
