import { Component, OnInit, OnDestroy} from '@angular/core';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../../messages/message.service';
import { Router } from '@angular/router';

import { PerfilService } from '../perfil.service';

import { Perfil, FuncionalidadePerfil } from '../../model/Perfil';

@Component({
  selector: 'app-visualiza-func-perfil',
  templateUrl: './visualiza-func-perfil.component.html',
  styleUrls: ['./visualiza-func-perfil.component.css']
})
export class VisualizaFuncPerfilComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  perfil: Perfil = new Perfil();

  constructor(private messageService: MessageService, private perfilService: PerfilService, private router: Router) { }

  ngOnInit(){
    this.getPerfil();
  }

  backtoList(){
    this.router.navigateByUrl('perfil/lista');
  }

  getPerfil(){
    this.perfilService.getPerfil(this.perfilService.getIdPerfilEditarVisualizar()).subscribe(
      data => {
        this.perfil = data;
      }, (error) => {
        console.log('Erro');
      }
    );
  }
}
