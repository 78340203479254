import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Perfil } from '../../model/Perfil';
import { Pessoa } from '../../model/Pessoa';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListaUsuarioService {

  listaPessoa = environment.apiUrl + "/pessoa/lista";
  removePessoa = environment.apiUrl + "/pessoa/deleta/";
  paginacaoPessoa = environment.apiUrl + "/pessoa/paginacao/";
  quantidadeIndice = environment.apiUrl + "/pessoa/quantidadeIndice";
  pesquisa = environment.apiUrl + "/pessoa/pesquisarPessoa";

  constructor(private http: HttpClient) { }

  searchPessoa(pessoa: Pessoa, paginacao: number): Observable<any[]>{
    return this.http.post<any>(this.pesquisa + '/' + paginacao.toString(), pessoa);
  }

  getListaPessoa(): Observable<any[]>{
    return this.http.get<any[]>(this.listaPessoa);
  }

  getListaPessoaPaginacao(indice: number): Observable<any>{
    return this.http.get<any>(this.paginacaoPessoa + indice.toString());
  }

  getQuantidadeIndice(): Observable<any>{
    return this.http.get<any>(this.quantidadeIndice);
  }

  deletePessoa(id: string): Observable<Pessoa>{
    return this.http.delete<Pessoa>(this.removePessoa + id);
  }
}
