import { Component, OnInit, OnDestroy} from '@angular/core';
import {Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { RegraTela, CondicaoTela, Condicao } from '../../model/RegraTela';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { EventEmitter } from '@angular/core';

@Component({
selector: 'dialog-editar',
templateUrl: 'dialog-editar.html',
})
export class DialogEditar {
  mensagem: string = "";
}


