import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  getFilesRegraPath = environment.apiUrl + '/arquivo/regra/all';
  getFilesTestePath = environment.apiUrl + '/arquivo/teste/all';


  constructor(private http: HttpClient, private router: Router) { }

  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();

    formdata.append('file', file);

    let url: string = environment.apiUrl +  this.router.url;
/*
    if(this.router.url == '/upload/regra'){
      url = environment.apiUrl + '/api/file/upload';
    }else if(this.router.url == '/upload/teste'){
      url = environment.apiUrl + '/api/file/upload';
    }
*/
    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);
  }

  getFilesRegra(paginacao: number): Observable<any[]> {
    return this.http.get<any>(this.getFilesRegraPath + '/' + paginacao.toString());
  }

  getFilesTeste(paginacao: number): Observable<any[]> {
    return this.http.get<any>(this.getFilesTestePath + '/' + paginacao.toString());
  }

  deletarUploadRegra(id: string): Observable<any> {
    const url = environment.apiUrl + "/arquivo/deletarUploadRegra/";
    return this.http.delete<any>(url + id)
  }

  deletarUploadTeste(id: string): Observable<any> {
    const url = environment.apiUrl + "/arquivo/deletarUploadTeste/";
    return this.http.delete<any>(url + id)
  }


  deletarAllUploadRegra(): Observable<any> {
    const url = environment.apiUrl + "/arquivo/deletarAllUploadRegra";
    return this.http.delete<any>(url)
  }

  deletarAllUploadTeste(): Observable<any> {
    const url = environment.apiUrl + "/arquivo/deletarAllUploadTeste";
    return this.http.delete<any>(url)
  }

}
