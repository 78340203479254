import { Component, OnInit, OnDestroy} from '@angular/core';

import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../../messages/message.service';

import { Perfil } from '../../model/Perfil';
import { PerfilService } from '../perfil.service';

@Component({
  selector: 'app-consulta-perfil',
  templateUrl: './consulta-perfil.component.html',
  styleUrls: ['./consulta-perfil.component.css']
})
export class ConsultaPerfilComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;
  data: Array<any>;
  currentPage = 1;
  totalRecord = 0;

  search: Perfil = new Perfil();
  pesquisa = false;
  perfil: Perfil = new Perfil();

  constructor(private router: Router, private messageService: MessageService, private perfilService: PerfilService) { }

  ngOnInit() {
    this.search.perfil = '';
    this.search.descricaoPerfil = '';
    this.datatablePerfil();
  }

  goToCriar(){
    this.router.navigateByUrl('/perfil/criar');
  }

  datatablePerfil(){
    try{
      this.blockUI.start('Processando...'); // Start blocking
      this.ngOnDestroy();
      this.perfilService.getListaPerfil(this.search, this.currentPage).subscribe(
        perfil => {
          this.data = perfil['content'];
          this.totalRecord = perfil['totalElements'];
          if (this.data === null || this.data.length === 0 ) {
            this.messageService.sendMessage('No se encontraron registros');
          }
          this.blockUI.stop();
        }, (error) => {
          this.blockUI.stop();
          console.log('Erro');
        }
      );
    } catch (e){
      this.blockUI.stop();
    }
  }

  setPage(i: number) {
    event.preventDefault();
    this.currentPage = i;
    if(!this.pesquisa){
      this.datatablePerfil();
    } else {
      this.pesquisarUsuario();
    }
  }

  pesquisarUsuario(){
    this.datatablePerfil();
  }

  ngOnDestroy(){
    this.messageService.clearMessage();
  }

  getPerfil(id: string){
    try{
      this.blockUI.start('Processando...'); // Start blocking
      this.ngOnDestroy();
      this.perfilService.getPerfil(id).subscribe(
      data => {
          this.perfil = data;
          this.blockUI.stop();
        }, (error) => {
          this.blockUI.stop();
          console.log('Erro');
        }
      );
    } catch (e) {
      this.blockUI.stop();
    }
  }

  deletar(id: string, perfil: string){
    try {
      this.blockUI.start('Processando...'); // Start blocking
      this.ngOnDestroy();
      this.perfilService.removeFuncionalidadePerfil(perfil).subscribe(
        data => {
          this.perfilService.removePerfil(id).subscribe(
            data => {
              this.ngOnInit();
              this.messageService.sendMessage('Removido com sucesso');
              this.blockUI.stop();
            }, (error) => {
              this.blockUI.stop();
              console.log('Erro');
            }
          );
        }, (error) => {
          this.blockUI.stop();
          console.log('Erro');
        }
      )
    } catch (e) {
      this.blockUI.stop();
    }
  }

  getPerfilAndIdVisualiza(item: any){
    try {
      this.ngOnDestroy();
      this.perfilService.setPerfilEditarVisualizar(item.perfil);
      this.perfilService.setIdPerfilEditarVisualizar(item.id);
      this.router.navigateByUrl('perfil/funcionalidade/visualizar');
    } catch (e) {
      this.blockUI.stop();
    }
  }

  getPerfilAndIdEdita(item: any){
    try {
      this.ngOnDestroy();
      this.perfilService.setPerfilEditarVisualizar(item.perfil);
      this.perfilService.setIdPerfilEditarVisualizar(item.id);
      this.router.navigateByUrl('perfil/funcionalidade/editar');
    } catch (e) {
      this.blockUI.stop();
    }
  }
}
