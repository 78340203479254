import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Pessoa } from '../model/Pessoa';
import {LoginPerfil} from '../model/LoginPerfil';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {
    this.getUsuarioLogado();
  }

  usuario: LoginPerfil;

  isAuthenticated(): boolean {
    this.getUsuarioLogado();
    return this.usuario.authenticated;
  }

  isAccessModuleRegras(): boolean {
    return (this.isAccessFunctionPendenteEnvio() || this.isAccessFunctionPendenteAprovacao() || this.isAccessFunctionCadastroRegras());
  }

  isAccessModuleUpload(): boolean {
    return (this.isAccessFunctionUploadRegras() || this.isAccessFunctionUploadTeste());
  }

  isAccessModuleAdministracao(): boolean {
    return (this.isAccessFunctionAdmUsuario() || this.isAccessFunctionAdmPecas() || this.isAccessFunctionAdmPalavraChave()
    || this.isAccessFunctionAdmPerfil());
  }

  isAccessFunctionAdmPerfil(): boolean {
    return this.usuario.accessFunctionAdmPerfil;
  }

  isAccessFunctionPendenteEnvio(): boolean {
    return this.usuario.accessFunctionPendenteEnvio;
  }

  isAccessFunctionPendenteAprovacao(): boolean {
    return this.usuario.accessFunctionPendenteAprovacao;
  }

  isAccessFunctionCadastroRegras(): boolean {
    return this.usuario.accessFunctionCadastroRegras;
  }

  isAccessFunctionUploadRegras(): boolean {
    return this.usuario.accessFunctionUploadRegras;
  }

  isAccessFunctionUploadTeste(): boolean {
    return this.usuario.accessFunctionUploadTeste;
  }

  isAccessFunctionAdmUsuario(): boolean {
    return this.usuario.accessFunctionAdmUsuario;
  }

  isAccessFunctionAdmPecas(): boolean {
    return this.usuario.accessFunctionAdmPecas;
  }

  isAccessFunctionAdmPalavraChave(): boolean {
    return this.usuario.accessFunctionAdmPalavraChave;
  }

  getUsuarioLogado() {
    if (sessionStorage['usuarioLogado'] != null && sessionStorage['usuarioLogado'] !== undefined) {
      const usuarioJson = sessionStorage['usuarioLogado'];
      this.usuario = JSON.parse(usuarioJson);
      //console.log('usuario legueado',usuarioJson);
    } else {
      //console.error('usuario no legueado');
      this.usuario = new LoginPerfil();
    }
  }
}
