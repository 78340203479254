export class Pessoa{
  id: string;
  nome: string;
  login: string;
  senha: string;

  email: string;

  status: string;
  sexo: string;
  perfil: string;
}
