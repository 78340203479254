import { Component, OnInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { RegraTela, CondicaoTela, Condicao } from '../../model/RegraTela';
import { MessageService } from '../../messages/message.service';
import { RegraService } from '../regra.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-editar',
  templateUrl: './editar-regra.component.html',
  styleUrls: ['./editar-regra.component.css']
})
export class EditarRegraComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  // condicoes: Array<any>;
  regra: RegraTela = new RegraTela();
  status = ["PENDENTE_APROVACAO", "INATIVO", "PENDENTE", "ATIVO"];
  operadores = ["NENHUM", "MAIOR", "MENOR", "IGUAL", "MAIOR_IGUAL", "MENOR_IGUAL"];
  condicoes = [];
  restoCondicoes = [];

  constructor(private regraService: RegraService, private http: HttpClient, private router: Router, private messageService: MessageService) { }

  backtoList(){
    this.router.navigateByUrl('regra/consulta');
  }

  ngOnInit() {
    this.getInfosRegra();
  }

  getInfosRegra(){
    try{
      this.blockUI.start('Processando...'); // Start blocking
      this.regraService.getRegraPorId(this.regraService.obterIdRegraEditarVisualizar()).subscribe(
        data => {
          this.regra = data;
          this.condicoes = [];
          this.restoCondicoes = [];
          for(var i=0; i < data.condicaoList.length; i++){
            if(data.condicaoList[i].parametro != "CNPJ_REPRESENTANTE" &&
              data.condicaoList[i].parametro != "CNPJ_ASSIST_TECNICA" &&
              data.condicaoList[i].parametro != "COD_LINHA" &&
              data.condicaoList[i].parametro != "COD_CATEGORIA" &&
              data.condicaoList[i].parametro != "COD_MARCA" ) {
               let condicao: CondicaoTela = new CondicaoTela;
               condicao.parametro = data.condicaoList[i].parametro;
               condicao.operador = data.condicaoList[i].operador;
               condicao.valor = data.condicaoList[i].valor;
               condicao.status = false;
               condicao.operador2 = data.condicaoList[i].operador2;
               condicao.valor2 = data.condicaoList[i].valor2;
               condicao.status2 = false;

               if (data.condicaoList[i].operador == null) {
                 condicao.operador = "NENHUM";
                 condicao.status = true;
               }
               if (data.condicaoList[i].operador2 == null) {
                 condicao.operador2 = "NENHUM";
                 condicao.status2 = true;
               }
               this.condicoes.push(condicao);
            } else {
              this.restoCondicoes.push(data.condicaoList[i]);
            }
          }
          this.blockUI.stop();
        }, (error) => {
          this.blockUI.stop();
          console.log('Erro')
        }
      );
    } catch (e) {
    }
  }

  verifica(event){
    if(event.operador == "NENHUM"){
      event.status = true;
      event.valor = null;
    } else {
      event.status = false;
    }

    if(event.operador2 == "NENHUM"){
      event.status2 = true;
      event.valor2 = null;
    } else {
      event.status2 = false;
    }
  }

  //Ok
  atualizar(regra: RegraTela) {
    try {
      this.blockUI.start('Processando...');
      this.ngOnDestroy();

      regra.condicaoList = [];

      regra.condicaoList = this.restoCondicoes;
      for(var i=0; i < regra.condicaoList.length; i++) {
        if(regra.condicaoList[i].parametro == "CNPJ_ASSIST_TECNICA" ){
          regra.condicaoList[i].valor = this.regra.cnpjAtTecnica
        }
        if(regra.condicaoList[i].parametro == "COD_LINHA"){
          regra.condicaoList[i].valor = this.regra.codLinha
        }
        if(regra.condicaoList[i].parametro == "CNPJ_REPRESENTANTE"){
          regra.condicaoList[i].valor = this.regra.cnpjRepresentante
        }
        if(regra.condicaoList[i].parametro == "COD_CATEGORIA"){
          regra.condicaoList[i].valor = this.regra.codCategoria
        }
        if(regra.condicaoList[i].parametro == "COD_MARCA"){
          regra.condicaoList[i].valor = this.regra.codMarca
        }
      }

      for(var i=0; i < this.condicoes.length; i++){
        let auxCondicao: Condicao = new Condicao();
        if(this.condicoes[i].operador === this.operadores[0]){
          this.condicoes[i].operador = null;
          this.condicoes[i].valor = null;
        }
        if(this.condicoes[i].operador2 === this.operadores[0]){
          this.condicoes[i].operador2 = null;
          this.condicoes[i].valor2 = null;
        }
        auxCondicao.parametro = this.condicoes[i].parametro;
        auxCondicao.operador = this.condicoes[i].operador;
        auxCondicao.valor = this.condicoes[i].valor;
        auxCondicao.operador2 = this.condicoes[i].operador2;
        auxCondicao.valor2 = this.condicoes[i].valor2;

        regra.condicaoList.push(auxCondicao);
      }

      this.regraService.updateRegra(regra).subscribe(
        data => {
          this.ngOnInit();
          this.blockUI.stop();
          this.messageService.sendMessage('Regra atualizada com sucesso');
        }, (error) => {
          this.blockUI.stop();
          console.log('Erro');
        }
      );
    } catch (e) {
      this.blockUI.stop();
    }
  }

  ngOnDestroy() {
    this.messageService.clearMessage();
  }

}
