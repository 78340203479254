import { Component, OnInit, OnDestroy} from '@angular/core';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../../messages/message.service';
import { Router } from '@angular/router';

import { Perfil, FuncionalidadePerfil } from '../../model/Perfil';

import { PerfilService } from '../perfil.service';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edita-func-perfil',
  templateUrl: './edita-func-perfil.component.html',
  styleUrls: ['./edita-func-perfil.component.css']
})
export class EditaFuncPerfilComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  perfil: Perfil = new Perfil();
  nomePerfil = "";

  constructor(private perfilService: PerfilService, private messageService: MessageService, private router: Router) {
  }

  backtoList(){
    this.router.navigateByUrl('perfil/lista');
  }

  ngOnInit(){
    this.getPerfil();
  }

  getPerfil(){
    this.perfilService.getPerfil(this.perfilService.getIdPerfilEditarVisualizar()).subscribe(
      data => {
        this.perfil = data;
        this.nomePerfil = data.perfil;
      }, (error) => {
        console.log('Erro');
      }
    );
  }

  ngOnDestroy(){
    this.messageService.clearMessage();
  }

  submitForm(){
    try {
      this.blockUI.start('Processando...'); // Start blocking
      this.ngOnDestroy();
      if(this.perfil.perfil === "" && this.perfil.descricaoPerfil === "") {
        this.messageService.sendMessage('Descrição de perfil e nome do perfil obrigatórios');
        this.blockUI.stop();
      } else if (this.perfil.perfil === "") {
        this.messageService.sendMessage('Nome do perfil obrigatório');
        this.blockUI.stop();
      } else if (this.perfil.descricaoPerfil === "") {
        this.messageService.sendMessage('Descrição de perfil obrigatório');
        this.blockUI.stop();
      } else {
        this.perfilService.verificaFuncionalidadePerfil(this.nomePerfil, this.perfil).subscribe(
          data => {
            if(data){
              this.perfilService.updateFuncionalidadePerfil(this.nomePerfil, this.perfil).subscribe(
                data => {
                  this.messageService.sendMessage('Perfil atualizado com sucesso');
                  this.blockUI.stop();
                }, (error) => {
                  this.blockUI.stop();
                  console.log('Erro');
                }
              );
            } else {
              this.messageService.sendMessage('Nome de perfil já existente');
              this.blockUI.stop();
            }
          }
        );
      }
    } catch (e) {
      this.blockUI.stop();
    }
  }
}
