import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Pessoa } from "../../model/Pessoa";
import { Perfil } from "../../model/Perfil";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CadastroUsuarioService {
  listaPerfil = environment.apiUrl + "/perfil/lista";

  cadastro = environment.apiUrl + "/pessoa/cadastro";
  verificaLogin = environment.apiUrl + "/pessoa/";
  atualizaPessoa = environment.apiUrl + "/pessoa/atualiza/";

  constructor(private http: HttpClient) {}

  setPessoa(pessoa: Pessoa): Observable<Pessoa> {
    return this.http.post<Pessoa>(this.cadastro, pessoa);
  }

  updatePessoa(id: string, pessoa: Pessoa): Observable<Pessoa> {
    //console.log(JSON.stringify(pessoa));
    //console.log(this.atualizaPessoa + id);
    var request = {
      nome:pessoa.nome,
      login:pessoa.login,
      senha:pessoa.senha,
      email:pessoa.email,
      status:pessoa.status,
      perfil:pessoa.perfil
    };
    return this.http.put<Pessoa>(this.atualizaPessoa + id, request);
  }

  getListaPerfil(): Observable<Perfil[]> {
    return this.http.get<Perfil[]>(this.listaPerfil);
  }

  getLogin(login: String): Observable<Pessoa> {
    return this.http.get<Pessoa>(this.verificaLogin + login);
  }
}
