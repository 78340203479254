import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RegraTela } from '../../../model/RegraTela';
import { MessageService } from '../../../messages/message.service';
import { RegraService } from '../../regra.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-visualiza-regra',
  templateUrl: './visualiza-regra.component.html',
  styleUrls: ['./visualiza-regra.component.css']
})
export class VisualizaRegraComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  condicoes: Array<any>;
  regra: RegraTela = new RegraTela();

  constructor(private regraService: RegraService, private http: HttpClient, private router: Router,
                private messageService: MessageService) { }

  ngOnInit() {
    this.getRegra();
  }

  backtoList(){
    this.router.navigateByUrl('regra/consulta');
  }

  getRegra(){
    this.blockUI.start('Processando...'); // Start blocking
    this.regraService.getRegraPorId(this.regraService.obterIdRegraEditarVisualizar()).subscribe(
      x => {
        this.regra = x;
        this.condicoes = x.condicaoList;
        this.blockUI.stop();
      }
    );
  }
}
