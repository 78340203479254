import { Component, OnInit, OnDestroy } from "@angular/core";
import { Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AfterViewInit, ViewChild } from "@angular/core";
import {
  HttpResponse,
  HttpEventType,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { MessageService } from "../../messages/message.service";
import { RegraTela } from "../../model/RegraTela";
import { CondicaoTela, Condicao } from "../../model/RegraTela";
import { RegraService } from "../regra.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Observable } from "rxjs/Observable";
import { DataSource } from "@angular/cdk/collections";

import { DialogVisualizar } from "./dialog-visualizar";
//import {DialogEditar} from './dialog-editar';

import { BlockUI, NgBlockUI } from "ng-block-ui";

import { EventEmitter } from "@angular/core";

/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
  selector: "app-consulta",
  templateUrl: "./consulta-regra.component.html",
  styleUrls: ["./consulta-regra.component.css"],
})
export class ConsultaRegraComponent implements OnInit, AfterViewInit {
  @BlockUI() blockUI: NgBlockUI;

  //displayedColumns: string[] = ['id', 'nome', 'status'];
  displayedColumns: string[] = [
    "position",
    "name",
    "weight",
    "symbol",
    "deletar",
  ];
  //dataSource = new MatTableDataSource(ELEMENT_DATA);
  //dataSource = new UserDataSource(this.regraService)
  //dataSource = new MatTableDataSource(Regra);
  //dataSource: MatTableDataSource<Regra>;
  //data:any;

  dataSource: MatTableDataSource<Regra>;

  regra: RegraTela = new RegraTela();

  regraEdit: RegraTela = new RegraTela();

  regraEdit2: RegraTela = new RegraTela();

  status = ["PENDENTE_APROVACAO", "INATIVO", "PENDENTE", "ATIVO"];
  operadores = [
    "NENHUM",
    "MAIOR",
    "MENOR",
    "IGUAL",
    "MAIOR_IGUAL",
    "MENOR_IGUAL",
  ];

  condicoes = [];
  restoCondicoes = [];

  resourcesLoaded: boolean = true;

  public width: Number;

  ngOnInit() {
    const data: Regra[] = [];

    //this.data = this.regraService.getAll().subscribe(response => data.push(response));

    this.blockUI.start("Processando..."); // Start blocking 2

    this.regraService.getAll().subscribe((response) => {
      
      //console.log('response',response);

      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.filterPredicate = this.customFilterPredicate;

      this.blockUI.stop();
    });
  }

  ngOnDestroy() {
    this.messageService.clearMessage();
  }

  customFilterPredicate(data: Regra, filter: String): boolean {
    //  if(data.status == "ATIVO")
    //    return true;

    //if(data.status.toString() == 'APAGADO')
    //  return false;

    return (
      data.nome
        .toString()
        .trim()
        .toLowerCase()
        .indexOf(filter.toLowerCase()) !== -1 ||
      data.status
        .toString()
        .trim()
        .toLowerCase()
        .indexOf(filter.toLowerCase()) !== -1
    );
  }

  downloadFile(event: any) {
    //this.blockUI.start('Downloading...'); // Start blocking 2

    console.log("download");

    this.width = 100;
    this.resourcesLoaded = false;
    event.target.disabled = true;

    this.regraService.exportRegrasPage().subscribe((response) => {
      let headerList = ["nome", "status", "condicaoList"];

      let condicaoHeaderList = [
        "parametro",
        "operador",
        "valor",
        "operador2",
        "valor2",
      ];

      let array =
        typeof response.content != "object"
          ? JSON.parse(response.content)
          : response.content;

      let str =
        "NOME DA REGRA,STATUS,DIAS_AT_SINISTRO,OPERADOR,VALOR,OPERADOR2,VALOR2,DIAS_REINCIDENCIA,OPERADOR,VALOR,OPERADOR2,VALOR2,PERC_IS,OPERADOR,VALOR,OPERADOR2,VALOR2,PER_PROX_PAL_CHAVE,OPERADOR,VALOR,OPERADOR2,VALOR2,PER_PROX_DESC_PECAS,OPERADOR,VALOR,OPERADOR2,VALOR2,CNPJ_REPRESENTANTE,OPERADOR,VALOR,OPERADOR2,VALOR2,CNPJ_ASSIST_TECNICA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_LINHA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_CATEGORIA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_MARCA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_MAO_OBRA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_PECA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_DESLOCAMENTO,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_DELIVERY,OPERADOR,VALOR,OPERADOR2,VALOR2";
      str += "\r\n";

      console.log("array.length = " + array.length);
      //console.log("array = " + array);

      for (let i = 0; i < array.length; i++) {
        //let line = (i+1)+'';
        let line = "";
        for (let index in headerList) {
          let head = headerList[index];
          //line += ',' + array[i][head];

          if (head == "condicaoList") {
            for (let j = 0; j < array[i][head].length; j++) {
              for (let index2 in condicaoHeaderList) {
                let head2 = condicaoHeaderList[index2];

                if (array[i][head][j][head2] == null) {
                  line += ",";
                } else {
                  line += "," + array[i][head][j][head2];
                }
              }
            }
          } else {
            if (line == "") {
              if (array[i][head] == null) {
              } else {
                line += array[i][head];
              }
            } else {
              if (array[i][head] == null) {
                line += ",";
              } else {
                line += "," + array[i][head];
              }
            }
          }
        }
        str += line + "\r\n";
      }

      let blob = new Blob(["\ufeff" + str], {
        type: "text/csv;charset=utf-8;",
      });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser =
        navigator.userAgent.indexOf("Safari") != -1 &&
        navigator.userAgent.indexOf("Chrome") == -1;
      if (isSafariBrowser) {
        //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", "regras.csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);

      if (array.length >= 20000) {
        this.regraService.exportRegrasPage2().subscribe((response) => {
          let headerList = ["nome", "status", "condicaoList"];

          let condicaoHeaderList = [
            "parametro",
            "operador",
            "valor",
            "operador2",
            "valor2",
          ];

          let array =
            typeof response.content != "object"
              ? JSON.parse(response.content)
              : response.content;

          let str =
            "NOME DA REGRA,STATUS,DIAS_AT_SINISTRO,OPERADOR,VALOR,OPERADOR2,VALOR2,DIAS_REINCIDENCIA,OPERADOR,VALOR,OPERADOR2,VALOR2,PERC_IS,OPERADOR,VALOR,OPERADOR2,VALOR2,PER_PROX_PAL_CHAVE,OPERADOR,VALOR,OPERADOR2,VALOR2,PER_PROX_DESC_PECAS,OPERADOR,VALOR,OPERADOR2,VALOR2,CNPJ_REPRESENTANTE,OPERADOR,VALOR,OPERADOR2,VALOR2,CNPJ_ASSIST_TECNICA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_LINHA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_CATEGORIA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_MARCA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_MAO_OBRA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_PECA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_DESLOCAMENTO,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_DELIVERY,OPERADOR,VALOR,OPERADOR2,VALOR2";
          str += "\r\n";

          console.log("array.length = " + array.length);

          for (let i = 0; i < array.length; i++) {
            let line = "";
            for (let index in headerList) {
              let head = headerList[index];

              if (head == "condicaoList") {
                for (let j = 0; j < array[i][head].length; j++) {
                  for (let index2 in condicaoHeaderList) {
                    let head2 = condicaoHeaderList[index2];

                    if (array[i][head][j][head2] == null) {
                      line += ",";
                    } else {
                      line += "," + array[i][head][j][head2];
                    }
                  }
                }
              } else {
                if (line == "") {
                  if (array[i][head] == null) {
                  } else {
                    line += array[i][head];
                  }
                } else {
                  if (array[i][head] == null) {
                    line += ",";
                  } else {
                    line += "," + array[i][head];
                  }
                }
              }
            }
            str += line + "\r\n";
          }

          let blob = new Blob(["\ufeff" + str], {
            type: "text/csv;charset=utf-8;",
          });
          let dwldLink = document.createElement("a");
          let url = URL.createObjectURL(blob);
          let isSafariBrowser =
            navigator.userAgent.indexOf("Safari") != -1 &&
            navigator.userAgent.indexOf("Chrome") == -1;
          if (isSafariBrowser) {
            //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
          }
          dwldLink.setAttribute("href", url);
          dwldLink.setAttribute("download", "regras2.csv");
          dwldLink.style.visibility = "hidden";
          document.body.appendChild(dwldLink);
          dwldLink.click();
          document.body.removeChild(dwldLink);

          if (array.length >= 20000) {
            this.regraService.exportRegrasPage3().subscribe((response) => {
              let headerList = ["nome", "status", "condicaoList"];

              let condicaoHeaderList = [
                "parametro",
                "operador",
                "valor",
                "operador2",
                "valor2",
              ];

              let array =
                typeof response.content != "object"
                  ? JSON.parse(response.content)
                  : response.content;

              let str =
                "NOME DA REGRA,STATUS,DIAS_AT_SINISTRO,OPERADOR,VALOR,OPERADOR2,VALOR2,DIAS_REINCIDENCIA,OPERADOR,VALOR,OPERADOR2,VALOR2,PERC_IS,OPERADOR,VALOR,OPERADOR2,VALOR2,PER_PROX_PAL_CHAVE,OPERADOR,VALOR,OPERADOR2,VALOR2,PER_PROX_DESC_PECAS,OPERADOR,VALOR,OPERADOR2,VALOR2,CNPJ_REPRESENTANTE,OPERADOR,VALOR,OPERADOR2,VALOR2,CNPJ_ASSIST_TECNICA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_LINHA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_CATEGORIA,OPERADOR,VALOR,OPERADOR2,VALOR2,COD_MARCA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_MAO_OBRA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_PECA,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_DESLOCAMENTO,OPERADOR,VALOR,OPERADOR2,VALOR2,VL_DELIVERY,OPERADOR,VALOR,OPERADOR2,VALOR2";
              str += "\r\n";

              console.log("array.length = " + array.length);

              for (let i = 0; i < array.length; i++) {
                let line = "";
                for (let index in headerList) {
                  let head = headerList[index];

                  if (head == "condicaoList") {
                    for (let j = 0; j < array[i][head].length; j++) {
                      for (let index2 in condicaoHeaderList) {
                        let head2 = condicaoHeaderList[index2];

                        if (array[i][head][j][head2] == null) {
                          line += ",";
                        } else {
                          line += "," + array[i][head][j][head2];
                        }
                      }
                    }
                  } else {
                    if (line == "") {
                      if (array[i][head] == null) {
                      } else {
                        line += array[i][head];
                      }
                    } else {
                      if (array[i][head] == null) {
                        line += ",";
                      } else {
                        line += "," + array[i][head];
                      }
                    }
                  }
                }
                str += line + "\r\n";
              }

              let blob = new Blob(["\ufeff" + str], {
                type: "text/csv;charset=utf-8;",
              });
              let dwldLink = document.createElement("a");
              let url = URL.createObjectURL(blob);
              let isSafariBrowser =
                navigator.userAgent.indexOf("Safari") != -1 &&
                navigator.userAgent.indexOf("Chrome") == -1;
              if (isSafariBrowser) {
                //if Safari open in new window to save file with random filename.
                dwldLink.setAttribute("target", "_blank");
              }
              dwldLink.setAttribute("href", url);
              dwldLink.setAttribute("download", "regras3.csv");
              dwldLink.style.visibility = "hidden";
              document.body.appendChild(dwldLink);
              dwldLink.click();
              document.body.removeChild(dwldLink);
            });
          } else {
            event.target.disabled = false;
            this.width = 0;
            this.resourcesLoaded = true;
          }
        });
      } else {
        event.target.disabled = false;
        this.width = 0;
        this.resourcesLoaded = true;
      }
    });
  }

  getRegra(id: string) {
    this.regraService.getRegraPorId(id).subscribe((x) => {
      this.regra = x;
    });
  }

  getRegra2(id: string) {
    this.regraService.getRegraPorId(id).subscribe((response) => {
      this.regraEdit.id = response.id;
      this.regraEdit.nome = response.nome;
      this.regraEdit.status = response.status;
      this.regraEdit.cnpjAtTecnica = response.cnpjAtTecnica;
      this.regraEdit.cnpjRepresentante = response.cnpjRepresentante;
      this.regraEdit.codCategoria = response.codCategoria;
      this.regraEdit.codLinha = response.codLinha;
      this.regraEdit.codMarca = response.codMarca;

      this.condicoes = [];
      for (var i = 0; i < response.condicaoList.length; i++) {
        if (
          response.condicaoList[i].parametro != "CNPJ_REPRESENTANTE" &&
          response.condicaoList[i].parametro != "CNPJ_ASSIST_TECNICA" &&
          response.condicaoList[i].parametro != "COD_LINHA" &&
          response.condicaoList[i].parametro != "COD_CATEGORIA" &&
          response.condicaoList[i].parametro != "COD_MARCA"
        ) {
          let condicao: CondicaoTela = new CondicaoTela();
          condicao.parametro = response.condicaoList[i].parametro;
          condicao.operador = response.condicaoList[i].operador;
          condicao.valor = response.condicaoList[i].valor;
          condicao.status = false;
          condicao.operador2 = response.condicaoList[i].operador2;
          condicao.valor2 = response.condicaoList[i].valor2;
          condicao.status2 = false;

          if (response.condicaoList[i].operador == null) {
            condicao.operador = "NENHUM";
            condicao.status = true;
          }
          if (response.condicaoList[i].operador2 == null) {
            condicao.operador2 = "NENHUM";
            condicao.status2 = true;
          }
          this.condicoes.push(condicao);
        }
      }

      this.regraEdit.condicaoList = this.condicoes;
    });
  }

  //atualizar(id: string, data: Regra)
  atualizar(id: string, data: any) {
    const index = this.dataSource.data.indexOf(data);

    this.dataSource.data.forEach((element) => {
      for (const key in element) {
        if (element[key] == data.id) {
          element["nome"] = data.nome;
          element["status"] = data.status;
        }
      }
    });

    this.regraEdit2.id = this.regraEdit.id;
    this.regraEdit2.nome = this.regraEdit.nome;
    this.regraEdit2.status = this.regraEdit.status;
    this.regraEdit2.cnpjAtTecnica = this.regraEdit.cnpjAtTecnica;
    this.regraEdit2.cnpjRepresentante = this.regraEdit.cnpjRepresentante;
    this.regraEdit2.codCategoria = this.regraEdit.codCategoria;
    this.regraEdit2.codLinha = this.regraEdit.codLinha;
    this.regraEdit2.codMarca = this.regraEdit.codMarca;

    let auxCondicao: Condicao = new Condicao();

    this.regraEdit2.condicaoList = [];

    for (var i = 0; i < this.regraEdit.condicaoList.length; i++) {
      let auxCondicao2: Condicao = new Condicao();

      auxCondicao2.parametro = this.regraEdit.condicaoList[i].parametro;

      if (this.regraEdit.condicaoList[i].operador == "NENHUM") {
        auxCondicao2.operador = null;
        auxCondicao2.valor = null;
      } else {
        auxCondicao2.operador = this.regraEdit.condicaoList[i].operador;
        auxCondicao2.valor = this.regraEdit.condicaoList[i].valor;
      }

      if (this.regraEdit.condicaoList[i].operador2 == "NENHUM") {
        auxCondicao2.operador2 = null;
        auxCondicao2.valor2 = null;
      } else {
        auxCondicao2.operador2 = this.regraEdit.condicaoList[i].operador2;
        auxCondicao2.valor2 = this.regraEdit.condicaoList[i].valor2;
      }

      this.regraEdit2.condicaoList.push(auxCondicao2);
    }

    this.regraService.updateRegra(this.regraEdit2).subscribe(
      (data2) => {
        console.log("Regra atualizada com sucesso");
        this.messageService.sendMessage("Regra atualizada com sucesso");
      },
      (error) => {
        this.messageService.sendMessage(error.error.message);
      }
    );
  }

  verifica(event) {
    if (event.operador == "NENHUM") {
      event.status = true;
      event.valor = null;
    } else {
      event.status = false;
    }

    if (event.operador2 == "NENHUM") {
      event.status2 = true;
      event.valor2 = null;
    } else {
      event.status2 = false;
    }
  }

  //deletar(id: string, regra2: Regra)
  deletar(id: string, regra2: any) {
    const index = this.dataSource.data.indexOf(regra2);

    this.dataSource.data.forEach((element) => {
      for (const key in element) {
        //if (!element[key] || element[key] === null) {
        // element[key] = '';
        //}
        if (element[key] == regra2.id) {
          element["status"] = "APAGADO";
          //element["nome"] = "APAGADO";
          //element["status"] = '';
          //element["nome"] = '';
        }
      }
    });

    //this.dataSource.filter = 'ATIVO';

    //return;

    try {
      //this.blockUI.start('Processando...'); // Start blocking
      //this.ngOnDestroy();
      this.regraService.deleteRegra(id).subscribe(
        (data) => {
          //console.log('Deletado com sucesso');
          this.messageService.sendMessage("Eliminado con éxito.");
        },
        (error) => {
          //this.blockUI.stop();
          //console.log('Erro');
          this.messageService.sendMessage(error.error.message);
        }
      );
    } catch (e) {
      //this.blockUI.stop();
    }
  }

  getDetalhe(id) {
    this.regraService.getRegraPorId(id).subscribe((response) => {
      this.dialog.open(DialogVisualizar, {
        data: {
          nome: response["nome"],
          status: response["status"],
          condicoes: response["condicaoList"],
        },
      });
    });
  }

  getApagar(id) {
    this.regraService.getRegraPorId(id).subscribe((response) => {
      const dialogRef = this.dialog.open(DialogDeletar, {
        data: {
          nome: response["nome"],
          status: response["status"],
          id: response["id"],
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log("The dialog was closed");
      });
    });
  }

  getEditar(id) {
    this.regraService.getRegraPorId(id).subscribe((response) => {
      this.condicoes = [];
      this.restoCondicoes = [];
      for (var i = 0; i < response.condicaoList.length; i++) {
        if (
          response.condicaoList[i].parametro != "CNPJ_REPRESENTANTE" &&
          response.condicaoList[i].parametro != "CNPJ_ASSIST_TECNICA" &&
          response.condicaoList[i].parametro != "COD_LINHA" &&
          response.condicaoList[i].parametro != "COD_CATEGORIA" &&
          response.condicaoList[i].parametro != "COD_MARCA"
        ) {
          let condicao: CondicaoTela = new CondicaoTela();
          condicao.parametro = response.condicaoList[i].parametro;
          condicao.operador = response.condicaoList[i].operador;
          condicao.valor = response.condicaoList[i].valor;
          condicao.status = false;
          condicao.operador2 = response.condicaoList[i].operador2;
          condicao.valor2 = response.condicaoList[i].valor2;
          condicao.status2 = false;

          if (response.condicaoList[i].operador == null) {
            condicao.operador = "NENHUM";
            condicao.status = true;
          }
          if (response.condicaoList[i].operador2 == null) {
            condicao.operador2 = "NENHUM";
            condicao.status2 = true;
          }
          this.condicoes.push(condicao);
        } else {
          this.restoCondicoes.push(response.condicaoList[i]);
        }
      }

      let dialogRef = this.dialog.open(DialogEditar2, {
        data: {
          id: response["id"],
          nome: response["nome"],
          status: response["status"],
          cnpjAtTecnica: response["cnpjAtTecnica"],
          cnpjRepresentante: response["cnpjRepresentante"],
          codCategoria: response["codCategoria"],
          codLinha: response["codLinha"],
          codMarca: response["codMarca"],
          //condicoes: response['condicaoList'],
          condicoes: this.condicoes,
        },
      });

      const sub = dialogRef.componentInstance.onAdd.subscribe(() => {
        console.log("AAA dasadf");
      });
    });
  }

  deleteAll() {
    console.log("Deletado com sucesso");

    this.width = 100;
    this.resourcesLoaded = false;

    try {
      this.regraService.deleteRegraAll().subscribe(
        (data) => {
          console.log("Deletado com sucesso");

          this.regraService.getAll().subscribe((response) => {
            this.dataSource = new MatTableDataSource(response);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.dataSource.filterPredicate = this.customFilterPredicate;

            this.width = 0;
            this.resourcesLoaded = true;

            this.messageService.sendMessage("Eliminado con éxito.");
          });
        },
        (error) => {
          //this.blockUI.stop();
          console.log("Erro");

          this.width = 0;
          this.resourcesLoaded = true;

          this.messageService.sendMessage("Error al eliminar.");
        }
      );
    } catch (e) {
      //this.blockUI.stop();
    }

    /*
    this.regraService.getAll().subscribe( response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.filterPredicate = this.customFilterPredicate;

      this.width = 0;
      this.resourcesLoaded = true;

      this.messageService.sendMessage('Deletado com sucesso.');
    });
*/

    /*
    try {
        this.regraService.deleteRegraAll().subscribe(
          data => {
            console.log('Deletado com sucesso');
          },(error) => {
            //this.blockUI.stop();
            console.log('Erro');
          }
        );
      } catch (e) {
        //this.blockUI.stop();
     }
*/
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
  }

  constructor(
    private regraService: RegraService,
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    public dialog: MatDialog
  ) {}

  //dataSource = new MatTableDataSource(this.regraService.getAll());

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    /*
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
*/

    /*
    if (filterValue != '')
      this.dataSource.filter = filterValue;
    else
      this.dataSource.filter = 'ATIVO';
*/

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

export class UserDataSource extends DataSource<any> {
  search: RegraTela = new RegraTela();

  currentPage = 1;

  constructor(private regraService: RegraService) {
    super();
  }
  connect(): Observable<Regra[]> {
    //return this.regraService.getAll();

    this.search.nome = "";
    this.search.status = "";
    //return this.regraService.searchRegras(this.search, this.currentPage);

    return this.regraService.getAll();
  }
  disconnect() {}
}

export interface Regra {
  nome: string;
  status: string;
  id: string;
  version: Number;
}

export interface ITableFilter {
  column: string;
  value: any;
}

export interface DialogData {
  nome: string;
  codMarca: string;
  codCategoria: string;
  status: string;
  cnpjAtTecnica: string;
  cnpjRepresentante: string;
  codLinha: string;
  condicoes: Array<Condicao>;
}

@Component({
  selector: "dialog-editar2",
  templateUrl: "dialog-editar.html",
})
export class DialogEditar2 {
  mensagem: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private regraService: RegraService
  ) {}


  @BlockUI() blockUI: NgBlockUI;

  regra: RegraTela = new RegraTela();
  status = ["PENDENTE_APROVACAO", "INATIVO", "PENDENTE", "ATIVO"];
  operadores = [
    "NENHUM",
    "MAIOR",
    "MENOR",
    "IGUAL",
    "MAIOR_IGUAL",
    "MENOR_IGUAL",
  ];
  condicoes = [];
  restoCondicoes = [];



  onAdd = new EventEmitter();

  //atualizar(regra: RegraTela) {
  atualizar(data: any) {
    this.regra.id = data.id;
    this.regra.nome = data.nome;
    this.regra.status = data.status;
    this.regra.cnpjAtTecnica = data.cnpjAtTecnica;
    this.regra.cnpjRepresentante = data.cnpjRepresentante;
    this.regra.codCategoria = data.codCategoria;
    this.regra.codLinha = data.codLinha;
    this.regra.codMarca = data.codMarca;

    let auxCondicao: Condicao = new Condicao();

    this.regra.condicaoList = [];

    /*
    auxCondicao.parametro = "CNPJ_ASSIST_TECNICA";
    auxCondicao.valor = data.cnpjAtTecnica;
    auxCondicao.operador = "IGUAL";
    this.regra.condicaoList.push(auxCondicao);

    auxCondicao.parametro = "COD_LINHA";
    auxCondicao.valor = data.codLinha;
    auxCondicao.operador = "IGUAL";
    this.regra.condicaoList.push(auxCondicao);

    auxCondicao.parametro = "CNPJ_REPRESENTANTE";
    auxCondicao.valor = data.cnpjRepresentante;
    auxCondicao.operador = "IGUAL";
    this.regra.condicaoList.push(auxCondicao);

    auxCondicao.parametro = "COD_CATEGORIA";
    auxCondicao.valor = data.codCategoria;
    auxCondicao.operador = "IGUAL";
    this.regra.condicaoList.push(auxCondicao);

    auxCondicao.parametro = "COD_MARCA";
    auxCondicao.valor = data.codMarca;
    auxCondicao.operador = "IGUAL";
    this.regra.condicaoList.push(auxCondicao);
*/

    for (var i = 0; i < data.condicoes.length; i++) {
      let auxCondicao2: Condicao = new Condicao();

      auxCondicao2.parametro = data.condicoes[i].parametro;

      if (data.condicoes[i].operador == "NENHUM") {
        auxCondicao2.operador = null;
        auxCondicao2.valor = null;
      } else {
        auxCondicao2.operador = data.condicoes[i].operador;
        auxCondicao2.valor = data.condicoes[i].valor;
      }

      if (data.condicoes[i].operador2 == "NENHUM") {
        auxCondicao2.operador2 = null;
        auxCondicao2.valor2 = null;
      } else {
        auxCondicao2.operador2 = data.condicoes[i].operador2;
        auxCondicao2.valor2 = data.condicoes[i].valor2;
      }

      this.regra.condicaoList.push(auxCondicao2);
    }

    this.regraService.updateRegra(this.regra).subscribe(
      (data2) => {
        //          this.ngOnInit();
        //          this.blockUI.stop();
        //          this.messageService.sendMessage('Regra atualizada com sucesso');

        this.mensagem = "Regra atualizada com sucesso";
        console.log("Regra atualizada com sucesso");
      },
      (error) => {
        //          this.blockUI.stop();
        //          console.log('Erro');
      }
    );
  }
}

@Component({
  selector: "dialog-deletar",
  templateUrl: "dialog-deletar.html",
})
export class DialogDeletar {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private regraService: RegraService
  ) {}

  mensagem: string = "";

  deletar(id: string) {
    try {
      //this.blockUI.start('Processando...'); // Start blocking
      //this.ngOnDestroy();
      this.regraService.deleteRegra(id).subscribe(
        (data) => {
          //this.ngOnInit();
          //this.messageService.sendMessage('Deletado com sucesso');
          //this.blockUI.stop();
          this.mensagem = "Deletado com sucesso";
        },
        (error) => {
          //this.blockUI.stop();
          console.log("Erro");
        }
      );
    } catch (e) {
      //this.blockUI.stop();
    }
  }
}
