export class RegraTela{
  id: string;
  nome: string;
  status: string;
  cnpjAtTecnica: string;
  cnpjRepresentante: string
  codCategoria: string;
  codLinha:string;
  codMarca: string;

  condicaoList: Array<any>;
}

export class Condicao {
  parametro: string;
  operador: string;
  valor: string;
  operador2: string;
  valor2: string;
}

export class CondicaoTela{
  parametro: string;
  operador: string;
  valor: string;
  status: boolean;
  operador2: string;
  valor2: string;
  status2: boolean;
}
