import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Peca } from '../model/Peca';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PecaService {

  cadastroPeca = environment.apiUrl + "/peca/criarPeca";
  paginacaoPeca = environment.apiUrl + "/peca/pesquisarPeca";
  buscarPeca = environment.apiUrl + "/peca/buscarPeca";
  editarPeca = environment.apiUrl + "/peca/atualizaPeca";
  removePeca = environment.apiUrl + "/peca/removePeca";

  constructor(private http: HttpClient) { }

  criarPeca(peca: Peca): Observable<any> {
    return this.http.post<any>(this.cadastroPeca, peca);
  }

  getPeca(id: string): Observable<any> {
    return this.http.get<any>(this.buscarPeca + '/' + id);
  }

  searchPeca(peca: Peca, paginacao: number): Observable<any[]> {
    return this.http.post<any>(this.paginacaoPeca + '/' + paginacao.toString(), peca);
  }

  atualizarPeca(peca: Peca): Observable<any> {
    return this.http.put<any>(this.editarPeca, peca);
  }

  deletarPeca(id: string): Observable<any> {
    return this.http.delete<any>(this.removePeca + id)
  }
}
