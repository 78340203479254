import { Component, OnInit, OnDestroy} from '@angular/core';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../../messages/message.service';
import { Router } from '@angular/router';

import { Perfil, FuncionalidadePerfil } from '../../model/Perfil';

import { PerfilService } from '../perfil.service';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-criar-perfil',
  templateUrl: './criar-perfil.component.html',
  styleUrls: ['./criar-perfil.component.css']
})
export class CriarPerfilComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;
  perfil: Perfil = new Perfil();

  constructor(private perfilService: PerfilService, private messageService: MessageService, private router: Router) {
  }

  ngOnInit(){
  }

  ngOnDestroy(){
    this.messageService.clearMessage();
  }

  backtoList(){
    this.router.navigateByUrl('perfil/lista');
  }

  submitForm(){
    try {
      this.blockUI.start('Processando...'); // Start blocking
      this.ngOnDestroy();
        this.perfilService.saveFuncionalidadePerfil(this.perfil).subscribe(
          data => {
            console.log(data);
            if(data === null){
              this.messageService.sendMessage('Nome de perfil já existente');
              this.blockUI.stop();
            } else {
              this.messageService.sendMessage('Perfil criado com sucesso');
              this.blockUI.stop();
            }
          }, (error) => {
            this.messageService.sendMessage('Preencha todas as informações para cadastro');
            this.blockUI.stop();
            console.log('Erro');
          }
        );
    } catch (e) {
      this.blockUI.stop();
    }
  }
}
