import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PalavraChave } from '../model/PalavraChave';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PalavraChaveService {

  cadastroPalavraChave = environment.apiUrl + "/palavrachave/criarPalavraChave";
  paginacaoPalavraChave = environment.apiUrl + "/palavrachave/pesquisarPalavraChave";
  buscarPalavraChave = environment.apiUrl + "/palavrachave/buscarPalavraChave";
  editarPalavraChave = environment.apiUrl + "/palavrachave/atualizaPalavraChave";
  removePalavraChave = environment.apiUrl + "/palavrachave/removePalavraChave";

  constructor(private http: HttpClient) { }

  criarPalavraChave(palavraChave: PalavraChave): Observable<any>{
     return this.http.post<any>(this.cadastroPalavraChave, palavraChave);
  }

  getPalavraChave(id: string): Observable<any>{
    return this.http.get<any>(this.buscarPalavraChave + '/' + id);
  }

  searchPalavraChave(palavraChave: PalavraChave, paginacao: number): Observable<any[]>{
      return this.http.post<any>(this.paginacaoPalavraChave + '/' + paginacao.toString(), palavraChave);
  }

  atualizarPalavraChave(palavraChave: PalavraChave): Observable<any>{
      return this.http.put<any>(this.editarPalavraChave + '/' + palavraChave.id, palavraChave);
  }

  deletarPalavraChave(id: string): Observable<any>{
      return this.http.delete<any>(this.removePalavraChave + '/' + id)
  }
}
