import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Pessoa } from '../model/Pessoa';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from '../messages/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy  {

  @BlockUI() blockUI: NgBlockUI;
  pessoa: Pessoa;
  form: FormGroup;
  msgError = false;

  constructor(private router: Router, private loginService: LoginService, private messageService: MessageService) {
    this.form = new FormGroup({
      login: new FormControl(),
      senha: new FormControl()
    });
    this.pessoa = {
      id: '',
      nome: '',
      login: '',
      senha: '',
      email: '',
      status: '',
      sexo: '',
      perfil: ''
    };
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.messageService.clearMessage();
  }

  onSubmit() {
    this.blockUI.start('Processando...'); // Start blocking 2
    this.ngOnDestroy();
    try {
      this.pessoa.login = this.form.get('login').value;
      this.pessoa.senha = this.form.get('senha').value;

      console.log(this.pessoa);

      this.loginService.login(this.pessoa).subscribe(
        data => {
          //console.log(data);
          if (data === null || data['authenticated'] === false) {
            this.messageService.sendMessage('Inicio de sesión o contraseña inválidos .');
          } else {
            sessionStorage['usuarioLogado'] = JSON.stringify(data);
            this.router.navigateByUrl('home');
          }
          this.blockUI.stop();
        },
        err => {
          alert(err.statusText);
          this.blockUI.stop();
          this.messageService.sendMessage('Se produjo un error interno, consulte la consola del navegador para obtener más detalles .' + err);
        }
      );

    } catch (e) {
      this.blockUI.stop();
    }
  }
}
