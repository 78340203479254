import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RoutingModule } from '../routing/routing.module';
import { LoginComponent } from '../login/login.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, LoginComponent],
  exports: [LayoutComponent]
})
export class UiModule { }
