import { Component, OnInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../../messages/message.service';
import { PecaService } from '../peca.service';
import { Peca } from '../../model/Peca';


@Component({
  selector: 'app-consulta-peca',
  templateUrl: './consulta-peca.component.html',
  styleUrls: ['./consulta-peca.component.css']
})
export class ConsultaPecaComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;
    data: Array<any>;
    currentPage = 1;
    totalRecord = 0;

    search: Peca = new Peca();
    peca: Peca = new Peca();
    pecaCriar: Peca = new Peca();

    constructor(private router: Router, private messageService: MessageService,
    private pecaService: PecaService) { }

    ngOnInit() {
      this.search = new Peca();
      this.search.nome = '';
      this.datatableRegrasPendentes();
    }

    setPage(i: number) {
      event.preventDefault();
      this.currentPage = i;
      this.ngOnInit();
    }

    salvar() {
      this.ngOnDestroy();
      if (this.pecaCriar === null || this.pecaCriar.nome === null || this.pecaCriar.nome.trim() === '') {
        this.messageService.sendMessage('A Peca não foi salva, campo nome da peça deve ser preenchido.');
      } else {
        this.pecaService.criarPeca(this.pecaCriar).subscribe(
          data => {
            this.ngOnInit();
            this.messageService.sendMessage('Salvo com sucesso.');
          }
        );
      }

    }

    atualizar() {
      this.ngOnDestroy();
      if (this.peca === null || this.peca.nome === null || this.peca.nome.trim() === '') {
        this.messageService.sendMessage('A Peca não foi atualizada, campo nome da peça deve ser preenchido.');
      } else {
        this.pecaService.atualizarPeca(this.peca).subscribe(
          data => {
            this.ngOnInit();
            this.messageService.sendMessage('Atualizado com sucesso.');
          }
        );
      }

    }

    remover(id: string) {
      this.ngOnDestroy();
      this.pecaService.deletarPeca(id).subscribe(
        data => {
          this.ngOnInit();
          this.messageService.sendMessage('Removido com sucesso');
        }
      );
    }

    getPeca(id: string) {
      this.pecaService.getPeca(id).subscribe(
        data => {
          this.peca = data;
        }
      );
    }

    datatableRegrasPendentes() {
      try {
            this.blockUI.start('Processando...'); // Start blocking
            this.ngOnDestroy();
            this.pecaService
              .searchPeca(this.search, this.currentPage)
              .subscribe(
                    pecas => {
                      this.data = pecas['content'];
                      this.totalRecord = pecas['totalElements'];
                      if (this.data === null || this.data.length === 0 ) {
                        this.messageService.sendMessage('No se encontraron registros');
                      }
                      this.blockUI.stop();
                },

                  (error) => {
                    this.blockUI.stop();
                    console.log('Erro');
                  }
                );
          } catch (e) {
            this.blockUI.stop();
          }
    }

    ngOnDestroy() {
        this.messageService.clearMessage();
    }

  limpar() {
    this.pecaCriar = new Peca();
    this.pecaCriar.nome = '';
  }
}
