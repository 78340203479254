import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BlockUIModule } from 'ng-block-ui';
import { RegraUploadComponent } from './upload/regra-upload/regra-upload.component';
import { TesteUploadComponent } from './upload/teste-upload/teste-upload.component';
import { RegraPendenteComponent } from './regra/regra-pendente/regra-pendente.component';
import { RegraPendenteAprovacaoComponent } from './regra/regra-pendente-aprovacao/regra-pendente-aprovacao.component';
import { CadastroUsuarioComponent } from './admin/cadastro-usuario/cadastro-usuario.component';
import { ListaUsuarioComponent } from './admin/lista-usuario/lista-usuario.component';
import { ConsultaRegraComponent } from './regra/consulta-regra/consulta-regra.component';

import { DialogVisualizar } from './regra/consulta-regra/dialog-visualizar';
//import { DialogEditar } from './regra/consulta-regra/dialog-editar';
import { DialogEditar2 } from './regra/consulta-regra/consulta-regra.component';
import { DialogDeletar } from './regra/consulta-regra/consulta-regra.component';


import { ConsultaPecaComponent } from './peca/consulta-peca/consulta-peca.component';
import { ConsultaPalavraChaveComponent } from './palavra-chave/consulta-palavra-chave/consulta-palavra-chave.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UiModule } from './ui/ui.module';
import { HomeComponent } from './home/home.component';
import { VisualizaRegraComponent } from './regra/visualiza-regra/visualiza-regra/visualiza-regra.component';
import { EditarRegraComponent } from './regra/editar-regra/editar-regra.component';
import { ConsultaPerfilComponent } from './perfil/consulta-perfil/consulta-perfil.component';
import { VisualizaFuncPerfilComponent } from './perfil/visualiza-func-perfil/visualiza-func-perfil.component';
import { EditaFuncPerfilComponent } from './perfil/edita-func-perfil/edita-func-perfil.component';
import { CriarPerfilComponent } from './perfil/criar-perfil/criar-perfil.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTableModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditarUsuarioComponent } from './admin/editar-usuario/editar-usuario.component';
import { CadastroRegraComponent } from './regra/cadastro-regra/cadastro-regra.component';
import { DialogEditar } from './regra/consulta-regra/dialog-editar';


@NgModule({
  declarations: [
    AppComponent,
    RegraUploadComponent,
    TesteUploadComponent,
    RegraPendenteComponent,
    RegraPendenteAprovacaoComponent,
    CadastroUsuarioComponent,
    ListaUsuarioComponent,
    ConsultaRegraComponent,
    ConsultaPecaComponent,
    ConsultaPalavraChaveComponent,
    HomeComponent,
    VisualizaRegraComponent,
    CadastroRegraComponent,
    EditarUsuarioComponent,
    EditarRegraComponent,
    ConsultaPerfilComponent,
    VisualizaFuncPerfilComponent,
    EditaFuncPerfilComponent,
    CriarPerfilComponent,
    DialogVisualizar,
    DialogEditar,
    DialogEditar2,
    DialogDeletar
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    NgxPaginationModule,
    BlockUIModule.forRoot(),
    BrowserAnimationsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  //entryComponents: [DialogVisualizar,DialogEditar,DialogEditar2],
  entryComponents: [DialogVisualizar,DialogEditar2,DialogDeletar],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
