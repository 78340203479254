import { Component, OnInit, OnDestroy} from '@angular/core';

import { ListaUsuarioService } from './lista-usuario.service';

import { Perfil } from '../../model/Perfil';
import { Pessoa } from '../../model/Pessoa';
import { CadastroUsuarioService } from '../cadastro-usuario/cadastro-usuario.service';

import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../../messages/message.service';

@Component({
  selector: 'app-lista',
  templateUrl: './lista-usuario.component.html',
  styleUrls: ['./lista-usuario.component.css']
})
export class ListaUsuarioComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  data: Array<any>;
  currentPage = 1;
  totalRecord = 0;

  pessoa: Pessoa = new Pessoa();

  search: Pessoa = new Pessoa();
  pesquisa = false;

  status = ["Ativo", "Inativo"];

  lista: Perfil[];

  constructor(private listaUsuarioService: ListaUsuarioService,
  private cadastroUsuarioService: CadastroUsuarioService,
   private router: Router, private messageService: MessageService) {
    this.listaPerfil();
  }

  ngOnInit() {
    this.search = new Pessoa();
    this.search.nome = '';
    this.search.login = '';
    this.datatableUsuarios();
  }

  datatableUsuarios() {
      try {
        this.blockUI.start('Processando...'); // Start blocking
        this.ngOnDestroy();
        this.listaUsuarioService
          .searchPessoa(this.search, this.currentPage)
          .subscribe(
                pessoas => {
                  this.data = pessoas['content'];
                  this.totalRecord = pessoas['totalElements'];
                  if (this.data === null || this.data.length === 0 ) {
                    this.messageService.sendMessage('No se encontraron registros ');
                  }
                  this.blockUI.stop();
            },
              (error) => {
                this.blockUI.stop();
                console.log('Erro');
              }
            );
      } catch (e) {
        this.blockUI.stop();
      }
    }

  pesquisarUsuario(){
     this.datatableUsuarios();
  }

  setPage(i: number) {
      event.preventDefault();
      this.currentPage = i;
      if(!this.pesquisa){
        this.datatableUsuarios();
      } else {
        this.pesquisarUsuario();
      }
  }

  ngOnDestroy() {
    this.messageService.clearMessage();
  }

   listaPerfil(){
    this.cadastroUsuarioService.getListaPerfil().subscribe(data => {
        this.lista = data;
      }
    );
   }

  editar(){
      try{
      this.blockUI.start('Processando...'); // Start blocking
      this.ngOnDestroy();
      this.cadastroUsuarioService.getLogin(this.pessoa.login).subscribe(
        data => {
          if(data != null){
            this.cadastroUsuarioService.updatePessoa(this.pessoa.id, this.pessoa).subscribe(
              data => {
                this.ngOnInit();
                this.messageService.sendMessage('Atualizado com sucesso');
                this.blockUI.stop();
              }, (error) => {
                this.blockUI.stop();
                console.log('Erro');
              }
            );
          }
        }
      );
      } catch(e) {
        this.blockUI.stop();
      }
    }

  goToCriarNovoUsuario(){
    this.router.navigateByUrl("admin/cadastro");
  }

  getPessoa(login: string){
    this.ngOnDestroy();
    this.cadastroUsuarioService.getLogin(login).subscribe(
      data => {
        this.pessoa = data;
      }
    )
  }

  excluir(id: string){
    try {
      this.ngOnDestroy();
      this.listaUsuarioService.deletePessoa(id).subscribe(
         data => {
            this.ngOnInit();
            this.messageService.sendMessage('Removido com sucesso');
            this.blockUI.stop();
         }, (error) => {
            this.blockUI.stop();
            console.log('Erro');
         }
      );
    } catch(e){
      this.blockUI.stop();
    }
  }
}
