export class LoginPerfil {
  id: string;
  nome: string;
  login: string;
  email: string;
  perfil: string;
  accessFunctionPendenteEnvio: boolean;
  accessFunctionPendenteAprovacao: boolean;
  accessFunctionCadastroRegras: boolean;
  accessFunctionUploadRegras: boolean;
  accessFunctionUploadTeste: boolean;
  accessFunctionAdmUsuario: boolean;
  accessFunctionAdmPecas: boolean;
  accessFunctionAdmPalavraChave: boolean;
  accessFunctionAdmPerfil: boolean;
  authenticated: boolean;
}
