import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { RegraTela } from '../model/RegraTela';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};


@Injectable({
  providedIn: 'root'
})
export class RegraService {

  paginacaoRegraPendente = environment.apiUrl + "/regra/pendentesPaginada";
  paginacaoRegraPendenteAprovacao = environment.apiUrl + "/regra/pendentesAprovacaoPaginada";
  idRegraEditarVisualizar = new BehaviorSubject<string>('');

  @BlockUI() blockUI: NgBlockUI;

  constructor(private http: HttpClient, private router: Router) { }

  alteraIdRegraEditarVisualizar(valor: string) {
    this.idRegraEditarVisualizar.next(valor);
  }

  obterIdRegraEditarVisualizar(): string {
    return this.idRegraEditarVisualizar.getValue();
  }

 deleteRegra(id: string): Observable<any> {
    const url = environment.apiUrl + "/regra/deletarRegra/";
    return this.http.delete<any>(url + id)
  }

 deleteRegraAll(): Observable<any> {
    const url = environment.apiUrl + "/regra/deleteRegraAll";
    return this.http.delete<any>(url)
  }

  updateRegra(regra: RegraTela): Observable<any>{
    const url = environment.apiUrl + "/regra/atualizarRegra";
    return this.http.put<any>(url, regra);
  }

  getRegraPorId(id: string): Observable<any>{
    const url = environment.apiUrl + '/regra/idRegra/';
    return this.http.get(url + id);
  }

  searchRegras(regra: RegraTela, pagina: number): Observable<any> {
    const url = environment.apiUrl + "/regra/pesquisarRegra/";
    return this.http.post<any>(url + pagina.toString(), regra);
  }

  getAllRegras(): Observable<any[]>{
    const url = environment.apiUrl + '/regra/pendente';
    return this.http.get<any[]>(url);
  }

  getAll(): Observable<any>{
    const url = environment.apiUrl + '/regra/getAll';
    return this.http.get<any>(url);
  }

  getRegrasPendentesTestadas(): Observable<any> {
    const url = environment.apiUrl + '/regra/pendente';
    return this.http.get(url);
  }

  getRegrasPendentesSomenteTestadas(regra: RegraTela, paginacao: number): Observable<any[]> {
	  return this.http.post<any>(this.paginacaoRegraPendente + '/' + paginacao.toString(), regra);
  }

  getRegrasPendentesAprovacao(regra: RegraTela, paginacao: number): Observable<any[]> {
  	return this.http.post<any>(this.paginacaoRegraPendenteAprovacao + '/' + paginacao.toString(), regra);
  }

 enviarRegra (id: String): Observable<any> {
    const url = environment.apiUrl + '/regra/enviarAprovacao/';
    return this.http.put((url + id), {'id': id}, httpOptions);
  }

 enviarRegrasSelecionadas (array: RegraTela[]): Observable<any> {
    const url = environment.apiUrl + '/regra/enviarSelecionadasAprovacao';
    return this.http.put<any>(url, array);
  }

  atualizarStatus (id: String): Observable<any> {
    const url = environment.apiUrl + '/regra/atualizarStatus/';
    return this.http.put((url + id), {'id': id}, httpOptions);
  }

  aprovarRegrasSelecionadas (array: RegraTela[]): Observable<any> {
     const url = environment.apiUrl + '/regra/aprovarSelecionadas';
     return this.http.put<any>(url, array);
  }

  reprovarRegra (id: String): Observable<any> {
    const url = environment.apiUrl + '/regra/reprovar/';
    return this.http.put((url + id), {'id': id}, httpOptions);
  }

  reprovarRegrasSelecionadas (array: RegraTela[]): Observable<any> {
     const url = environment.apiUrl + '/regra/reprovarSelecionadas';
     return this.http.put<any>(url, array);
  }

  exportRegrasPage(): Observable<any>{
    const url = environment.apiUrl + '/regra/exportRegrasPage';
    return this.http.get<any>(url);
  }

  exportRegrasPage2(): Observable<any>{
    const url = environment.apiUrl + '/regra/exportRegrasPage2';
    return this.http.get<any>(url);
  }

  exportRegrasPage3(): Observable<any>{
    const url = environment.apiUrl + '/regra/exportRegrasPage3';
    return this.http.get<any>(url);
  }

  exportRegras(): Observable<any>{
    const url = environment.apiUrl + '/regra/exportRegras';
    return this.http.get<any>(url);
  }

}
