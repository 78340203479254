export class Perfil{
  id: string;
  perfil: string;
  descricaoPerfil: string;

  accessFunctionPendenteEnvio: boolean = false;
  accessFunctionPendenteAprovacao: boolean = false;
  accessFunctionCadastroRegras: boolean = false;
  accessFunctionUploadRegras: boolean = false;
  accessFunctionUploadTeste: boolean = false;
  accessFunctionAdmUsuario: boolean = false;
  accessFunctionAdmPecas: boolean = false;
  accessFunctionAdmPalavraChave: boolean = false;
  accessFunctionAdmPerfil: boolean = false;
}

export class FuncionalidadePerfil {
  id: string;
  perfil: string;
  codigofuncionalidade: string;
  userUpdate: string;
  dataUpdate: string;
  descricao: string;
}
