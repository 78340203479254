import { Component, OnInit, OnDestroy} from '@angular/core';
import {Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Condicao } from 'src/app/model/RegraTela';


export interface DialogData {
  nome:string;
  codMarca:string;
  codCategoria: string;
  status:string;
  cnpjAtTecnica:string;
  cnpjRepresentante:string;
  codLinha:string;
  condicoes:Array<Condicao>;
}

@Component({
selector: 'dialog-visualizar',
templateUrl: 'dialog-visualizar.html',
})
export class DialogVisualizar {
constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
