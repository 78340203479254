import { Component, OnInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from '../../messages/message.service';
import { PalavraChaveService } from '../palavra-chave.service';
import { PalavraChave } from '../../model/PalavraChave';


@Component({
  selector: 'app-consulta-palavra-chave',
  templateUrl: './consulta-palavra-chave.component.html',
  styleUrls: ['./consulta-palavra-chave.component.css']
})
export class ConsultaPalavraChaveComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;
  data: Array<any>;
  currentPage = 1;
  totalRecord = 0;
  search: PalavraChave = new PalavraChave();
  palavraChave: PalavraChave = new PalavraChave();
  palavraChaveCriar: PalavraChave = new PalavraChave();


  constructor(private router: Router, private messageService: MessageService,
  private palavraChaveService: PalavraChaveService) {}

  ngOnInit() {
    this.search = new PalavraChave();
    this.search.nome = '';
    this.datatableRegrasPendentes();
  }

  setPage(i: number) {
    event.preventDefault();
    this.currentPage = i;
    this.ngOnInit();
  }

  salvar() {
    this.ngOnDestroy();
    if (this.palavraChaveCriar === null || this.palavraChaveCriar.nome === null || this.palavraChaveCriar.nome.trim() === '') {
      this.messageService.sendMessage('A Palavra-Chave não foi salva, campo nome da palavra deve ser preenchido.');
    } else {
      this.palavraChaveService.criarPalavraChave(this.palavraChaveCriar).subscribe(
        data => {
          this.ngOnInit();
          this.messageService.sendMessage('Salvo com sucesso');
        }
      );
    }
  }

  atualizar() {
    this.ngOnDestroy();
    if (this.palavraChave === null || this.palavraChave.nome === null || this.palavraChave.nome.trim() === '') {
      this.messageService.sendMessage('A Palavra-Chave não foi atualizada, campo nome da palavra deve ser preenchido.');
    } else {
      this.palavraChaveService.atualizarPalavraChave(this.palavraChave).subscribe(
        data => {
          this.ngOnInit();
          this.messageService.sendMessage('Atualizado com sucesso');
        }
      );
    }
  }

  remover(id: string) {
    this.ngOnDestroy();
    this.palavraChaveService.deletarPalavraChave(id).subscribe(
      data => {
        this.ngOnInit();
        this.messageService.sendMessage('Removido com sucesso');
      }
    );
  }

  getPalavraChave(id: string) {
    this.palavraChaveService.getPalavraChave(id).subscribe(
      data => {
        this.palavraChave = data;
      }
    );
  }

  datatableRegrasPendentes() {
    try {
          this.blockUI.start('Processando...'); // Start blocking
          this.ngOnDestroy();
          this.palavraChaveService
            .searchPalavraChave(this.search, this.currentPage)
            .subscribe(
                  palavras => {
                    this.data = palavras['content'];
                    this.totalRecord = palavras['totalElements'];
                    if (this.data === null || this.data.length === 0 ) {
                      this.messageService.sendMessage('No se encontraron registros ');
                    }
                    this.blockUI.stop();
              },

                (error) => {
                  this.blockUI.stop();
                  console.log('Erro');
                }
              );
        } catch (e) {
          this.blockUI.stop();
        }
  }

  ngOnDestroy() {
      this.messageService.clearMessage();
  }

  limpar() {
    this.palavraChaveCriar = new PalavraChave();
    this.palavraChaveCriar.nome = '';
  }
}
