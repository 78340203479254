import { Component, OnInit, OnDestroy} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from '../../messages/message.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { RegraService } from '../regra.service';
import { RegraTela } from '../../model/RegraTela';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Component({
  selector: 'regra-pendente',
  templateUrl: './regra-pendente.component.html',
  styleUrls: ['./regra-pendente.component.css']
})

export class RegraPendenteComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;
  data: Array<any>;
  currentPage = 1;
  totalRecord = 0;
  selectedRows: RegraTela[] = [];

  search: RegraTela = new RegraTela();
  pesquisa = false;

  regraIdApagar: string;
  regraNameApagar: string;

  mensagemTela: string;

  constructor(private regraService: RegraService, private http:HttpClient,
    private router: Router,  private messageService: MessageService) {}

  ngOnInit() {
    this.search = new RegraTela();
    this.search.id = '';
    this.search.nome = '';
    this.searchByParameters();
    this.mensagemTela = '';
  }

  ngOnDestroy() {
    this.messageService.clearMessage();
  }

  setPage(i: number) {
    event.preventDefault();
    this.currentPage = i;
    if(!this.pesquisa){
      this.searchByParameters();
    } else {
      this.pesquisarRegra();
    }
  }

  pesquisarRegra(){
    this.searchByParameters();
  }

  searchByParameters() {
    try {
      this.blockUI.start('Processando...');
      this.ngOnDestroy();
      this.regraService
        .getRegrasPendentesSomenteTestadas(this.search, this.currentPage)
        .subscribe(
            regras => {
              this.data = regras ['regraTelaList'];
              this.totalRecord = regras ['totalRecord'];
                if (this.data === null || this.data.length === 0 ) {
                  this.messageService.sendMessage('No se encontraron registros');
                }
              this.blockUI.stop();
            },
            (error) => {
              this.blockUI.stop();
              console.log('Erro');
            }
          );
    } catch (e) {
      this.blockUI.stop();
    }
  }

  uncheckAll() {
    const inputs = document.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === 'checkbox') {
        if (inputs[i].id === 'checkbox_master') {
          inputs[i].checked = false;
        }
      }
    }
  }


  checkUncheckAll() {
    const inputs = document.getElementsByTagName('input');
    let checkbox_master;
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === 'checkbox') {
        if (inputs[i].id === 'checkbox_master') {
          checkbox_master = inputs[i].checked;
        }
        if (checkbox_master) {
          inputs[i].checked = true;

        } else {
          inputs[i].checked = false;
        }
      }
    }
  }

   enviarRegrasSelecionadas(){
      this.setSelectedItens()
      if(this.selectedRows.length != 0){
          try{
              this.blockUI.start('Processando...');
              this.ngOnDestroy();
              this.regraService.enviarRegrasSelecionadas(this.selectedRows)
                .subscribe(
                  result => {
                    if (this.data.length === 1) {
                      this.currentPage = (this.currentPage - 1);
                    }
                    this.search.id   = '';
                    this.search.nome = '';
                    this.searchByParameters();
                    this.messageService.sendMessage(result[0].description);
                    this.uncheckAll();
                    this.blockUI.stop();
                  },
                  error => {
                    this.messageService.sendMessage(error.error.message);
                    this.uncheckAll();
                    this.blockUI.stop();
                  }
                );
          }catch(e){
            this.blockUI.stop();
          }
      }
   }

   setSelectedItens() {
     let inputs = document.querySelectorAll<HTMLInputElement>("input.input_regra")
     this.selectedRows = [];
     for (let i = 0; i < inputs.length; i++) {
       if (inputs[i].type == "checkbox" && inputs[i].checked) {
         this.selectedRows.push(this.data[i])
       }
     }
   }

   enviarRegra(id: String) {
     try {
       this.blockUI.start('Processando...');
       this.ngOnDestroy();
       this.regraService.enviarRegra(id)
         .subscribe(
           result => {
             if (this.data.length === 1) {
               this.currentPage = (this.currentPage - 1);
             }
             this.search.id = '';
             this.search.nome = '';
             this.searchByParameters();
             this.messageService.sendMessage(result.message);
             this.blockUI.stop();
           },
           error => {
             this.messageService.sendMessage(error.error.message);
             this.blockUI.stop();
           }
         );
     } catch (e) {
       this.blockUI.stop();
     }
   }

  setRegraApagar(regraId: string, regraName: string)
  {
    this.regraIdApagar = regraId;
    this.regraNameApagar = regraName;
  }

  deletar(regraId: string, regraName: string)
  {
    try {
        this.regraService.deleteRegra(regraId).subscribe(
          data => {
            console.log('Deletado com sucesso');
            this.searchByParameters();
            this.messageService.sendMessage('Eliminado con éxito.');
          },(error) => {
            //this.blockUI.stop();
            console.log('Erro');
          }
        );
      } catch (e) {
        //this.blockUI.stop();
     }
  }

}
